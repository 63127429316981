<template>
  <router-view />
</template>
<!-- <script>
export default {
  created() {
    // Lógica para restablecer el estado de Vuex al cargar la página
    this.$store.commit('clearUserData'); // Por ejemplo, utiliza tu propia mutación
  },
};
</script> -->

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
