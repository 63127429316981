import axios from 'axios'
import store from '../store/index'
export default {
  install: (app, options) => {
    app.config.globalProperties.$axios = new (class {
      constructor() {
      }

      test() {
      }

      async post(
        url,
        payload,
        headers = { headers: { Authorization: store.state.token? store.state.token:null } },
      ) {
        const response = await axios.post(url, payload, headers)
        const {
          data,
          data: { result },
        } = response
        return result
        /*axios
                .post(url, data. headers)
                .then((response) => {
                    const { data, data: {result} } = response;
                    return result
                })
                .catch((error)=>{
                    console.log("there was an error", error)
                }); */
      }
      async patch(
        url,
        payload,
        headers = { headers: { Authorization: store.state.token? store.state.token:null } },
      ) {
        const response = await axios.patch(url, payload, headers)
        const {
          data,
          data: { result },
        } = response
        return result
        /*axios
                .post(url, data. headers)
                .then((response) => {
                    const { data, data: {result} } = response;
                    return result
                })
                .catch((error)=>{
                    console.log("there was an error", error)
                });*/
      }

      async get(url, headers = { headers: { Authorization: store.state.token? store.state.token:null } }) {
        // const response = await axios.get(url, headers);
        // const { data, data: {result} } = response;
        let resultDAta
       return axios
          .get(url, headers)
          .then(async (response) => {
            // const {
            //   data,
            //   data: { result, total_pages, page },
            // } = response
            // resultDAta = {result:result, total_pages:total_pages, page:page}
            return response.data
          })
          .catch((error) => {
            if (error.response.request.status === 401) {
              localStorage.removeItem('user')
              window.location.href = "/pages/login#/pages/login"
            }
            resultDAta = error
            return resultDAta
          })
      }

      async delete(url, headers = { headers: { Authorization: store.state.token? store.state.token:null } }) {
        const response = await axios.delete(url, headers)
        const {
          data,
          data: { result },
        } = response
        /*axios
                .get(url, headers)
                .then((response) => {
                    console.log("the response was", response)
                    const { data, data: {result} } = response;
                    console.log("the result is", result)
                    response = result
                })
                .catch((error) => {
                    console.log("there was an error", error)
                });*/
        return result
      }
    })()
  },
}
