// store/index.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';
import {notificationsModule} from './modules/notifications/notifications';
import { apiUrl } from '@/config';

export default createStore({
  state: {
    sidebarVisible: true,
    sidebarUnfoldable: false,
    visiblePostModal: false,
    visibleDeleteModal: false,
    isAuthenticated: false,
    user: null,
    name: null,
    token: null,
    accessType: null,
    userId: null,
    clientNameDashboard: null,
    searchInputValue: "",
    taskTemplateTab: false,
    showTaskTemplateDetails: null,
    showModalFilter: false,
    locationDetails: null,
    currentPage: "",
    clientEmploye: '',
    locationEmployee: '',
    roomEmployee: '',
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = true;
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = false;
    },
    setVisiblePostModal(state, value) {
      state.visiblePostModal = value;
    },
    setVisibleDeleteModal(state, value) {
      state.visibleDeleteModal = value;
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;
    },
    setAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setName(state, name) {
      state.name = name;
    },
    setAccessType(state, accessType) {
      state.accessType = accessType;
    },
    clearUserData(state) {
      state.user = null;
      state.token = null;
      state.name = null;
      state.accessType = null;
      state.isAuthenticated = false;
      state.userId = null;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setClientNameDashboard(state, clientNameDashboard) {
      state.clientNameDashboard = clientNameDashboard;
    },
    setSearchInputValue(state, value) {
      state.searchInputValue = value;
    },
    setTaskTemplateTab(state, value) {
      state.taskTemplateTab = value;
    },
    setShowTaskTemplateDetails(state, value) {
      state.showTaskTemplateDetails = value;
    },
    setShowModalFilter(state, value) {
      state.showModalFilter = value;
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setClientEmploye(state, value) {
      state.clientEmploye = value
    },
    setShowModalFilter(state, value) {
      state.showModalFilter = value
    },
    setLocationDetails(state, value) {
      state.locationDetails = value
    },
    setLocationEmployee(state, value) {
      state.locationEmployee = value
    },
    setRoomEmployee(state, value) {
      state.roomEmployee = value
    },
  },
  actions: {
    async loginAdmin({ commit }, credentials) {
      try {
        // Realizar la solicitud de inicio de sesión al servidor
        const response = await axios.post(apiUrl+'/sa-access/auth', credentials);

        // Extraer datos del usuario y token de la respuesta
        const user = response.data.result.username;
        const token = response.data.result.access_token;
        const name = response.data.result.first_name + " " + response.data.result.last_name;

        // Actualizar el estado de Vuex con la información recibida
        commit('setAuthenticated', true);
        commit('setUser', user);
        commit('setToken', token);
        commit('setName', name);
        commit('setAccessType', 'superAdmin');

        return true; // Indicar que el inicio de sesión fue exitoso
      } catch (error) {
        console.error(error);
        return false; // Indicar que hubo un error en el inicio de sesión
      }
    },
    logout({ commit }) {
      // Limpiar el estado de autenticación
    commit('clearUserData');
    localStorage.clear();
    localStorage.removeItem('vuex');
    console.log("logout");

    },
    getSearchInputValue({ commit, state }) {
      return state.searchInputValue;
    },
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    user: state => state.user,
    token: state => state.token,
    name: state => state.name,
    accessType: state => state.accessType,
    userId: state => state.userId,
    visiblePostModal: state => state.visiblePostModal,
    visibleDeleteModal: state => state.visibleDeleteModal,
    showModalFilter: state => state.showModalFilter,
    clientNameDashboard: state => state.clientNameDashboard,
    currentPage: state => state.currentPage,
  },
  modules: {notificationsModule},
  plugins: [createPersistedState(),],
});