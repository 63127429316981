<template>
  <div class="rating">
    <ul class="list">
      <li
        :key="star"
        v-for="star in maxStars"
        :class="{ active: star <= stars }"
        @click="rate(star)"
        :style="{ cursor: isReadOnly ? 'default' : 'pointer' }"
        class="star"
      >
        <font-awesome-icon icon="star" class="fas fa-spinner" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    grade: {
      type: Number,
      required: true,
    },
    maxStars: {
      type: Number,
      default: 5,
    },
    hasCounter: {
      type: Boolean,
      default: true,
    },
    isReadOnly: {
      type: Boolean,
      default: true, // Configura como 'true' para hacer que el componente sea de solo lectura
    },
  },
  data() {
    return {
      stars: this.grade,
    };
  },
  methods: {
    rate(star) {
      if (!this.isReadOnly && typeof star === 'number' && star <= this.maxStars && star >= 0) { // Verifica si no es de solo lectura
        this.stars = this.stars === star ? star - 1 : star;
        this.$emit('chageStart', this.stars);
      }
    },
  },
};
</script>

<style scoped lang="scss">
$active-color: #f3d23e;

.rating {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 22px;
  color: #a7a8a8;
}
.list {
  margin: 0 0 5px 0;
  padding: 0;
  list-style-type: none;
  &:hover {
    .star {
      color: $active-color;
    }
  }
}
.star {
  display: inline-block;
  cursor: pointer;
  &:hover {
    & ~ .star {
      &:not(.active) {
        color: inherit;
      }
    }
  }
}
.active {
  color: $active-color;
}

.active
{
  border-color: rgb(213, 165, 240) !important;
  background-color: transparent !important;
}
</style>
