export default [
  {
    component: 'CNavTitle',
    name: 'User',
  },

  {
    component: 'CNavItem',
    name: 'Clients',
    to: '/clientsUser',
    icon: 'cilUser',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Employees',
  //   to: '/employeesUsers',
  //   icon: 'cilUser',
  // },
]
