export default [
    {
      component: 'CNavItem',
      name: 'Clients',
      to: 'clients',
      icon: 'cilUser',
    },
    {
      component: 'CNavItem',
      name: 'Users',
      to: 'employees',
      icon: 'cilUser',
    },
]