export const notificationsModule = {
  namespaced: true, // Añade esta línea
  state: {
    showNotification: false,
    notificationMessage: '',
    notificationType: 'success',
  },
  mutations: {
    setNotification(state, { message, type }) {
      state.showNotification = true;
      state.notificationMessage = message;
      state.notificationType = type;
    },
    clearNotification(state) {
      state.showNotification = false;
      state.notificationMessage = '';
      state.notificationType = 'success';
    },
  },
};