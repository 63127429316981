// Notification.vue
<template>
  <div v-if="visible" class="notification" :class="notificationType">
    <div class="text-bold">
      {{ title }}
    </div>
    <div>
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    title: String,
    message: String,
    notificationType: String,
  },
}
</script>

<style scoped>
.notification {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  z-index: 1000000000;
  width: 400px;
  /* Agrega más estilos según sea necesario */
}
.primary {
  background-color: #7E39A4;
}
.secondary {
  background-color: #CC3A92;
}
.success {
  background-color: #6CE9A6;
}
.danger {
  background-color: #FF9890;
}
.warning {
  background-color: #EBE07F;
}
.info {
  background-color: #73B6E6;
}

.text-bold{
    font-weight: bold;
}

/* Agrega más clases según los tipos de notificación que necesites */
</style>
