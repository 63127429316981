// useScreenSize.js
import store from '../store/index'
export default function screenSize() {
    const state = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    const handleResize = () => {
      state.width = window.innerWidth;
      state.height = window.innerHeight;
      if (state.width < 768) {
         store.commit({
             type: 'updateSidebarVisible',
             value: false,
         })
      }
      else{
         store.commit({
             type: 'updateSidebarVisible',
             value: true,
         })
      }
    };
    window.addEventListener("resize", handleResize);
    return state;
  }